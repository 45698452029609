import React, { memo } from 'react';
import { useQuery } from '@apollo/client';
import { USER_PROBLEM_BY_LANGUAGE } from './query';
import { Chart } from 'primereact/chart';
import LeetcodeErrorCard from '../LeetcodeErrorCard/LeetcodeErrorCard';
import LoadingCard from '../LoadingCard/LoadingCard';
import { Languages } from '../../Types/LocalStorageCache';
import { getCache, setCache } from '../../utils/CacheLogic';
import { usernameNotFound } from '../../utils/Constants';
import { Tooltip } from 'primereact/tooltip';

type Props = {
  username: string;
};

type languageProblemCount = {
  languageName: string;
  problemsSolved: number;
};

const COMPONENT_KEY = 'Languages';

const QuestionLanguageCard = ({ username }: Props) => {
  let showCachedIcon = false; // No need to make this a state since it's only checked in first load.
  let cacheErrorMessage = '';

  // ----- query the data -----
  const { loading, error, data } = useQuery(USER_PROBLEM_BY_LANGUAGE, {
    variables: { username: username },
  });
  let languageList: Languages;

  if (loading) return <LoadingCard />;
  if (error) {
    if (error.message == usernameNotFound) {
      return <LeetcodeErrorCard errorType="usernameNotFound" />;
    }
    languageList = getCache(COMPONENT_KEY);
    if (languageList == null) {
      return <LeetcodeErrorCard errorType="networkError" />;
    }

    // Deal with the notification logic
    showCachedIcon = true;
    if (languageList.timestamp) {
      cacheErrorMessage = `This was loaded from cache at ${new Date(
        languageList.timestamp ?? '',
      ).toISOString()}, due to current network errors`;
    } else {
      cacheErrorMessage =
        'This was loaded from cache due to current network errors';
    }
  } else {
    languageList = {
      languages: data.matchedUser.languageProblemCount,
    };

    // save to cache
    setCache(COMPONENT_KEY, languageList);
  }
  // --------------------------

  const sortedLanguageList = [...languageList.languages].sort(
    (a: languageProblemCount, b: languageProblemCount) =>
      b.problemsSolved - a.problemsSolved,
  );
  const topKLanguageList = sortedLanguageList.slice(0, 5);
  const donutData = {
    labels: topKLanguageList.map(
      (lang: languageProblemCount) => lang.languageName,
    ),
    datasets: [
      {
        data: topKLanguageList.map(
          (lang: languageProblemCount) => lang.problemsSolved,
        ),
        backgroundColor: [
          '#00b8a3',
          '#ffc01e',
          '#ef4743',
          '#9fa1a4',
          '#4a4a4a',
          // ... more colors for additional languages
        ],
        hoverBackgroundColor: [
          '#00b8a3',
          '#ffc01e',
          '#ef4743',
          '#9fa1a4',
          '#4a4a4a',
          // ... more colors for additional languages
        ],
        borderColor: 'transparent',
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right', // Ensure this is consistent across all charts
        labels: {
          color: '#fff',
          font: {
            size: 16, // Ensure the font size is the same for all charts
          },
        },
      },
    },
    layout: {
      // padding: {
      //   right: 100 // Adjust this to move the legend away from the chart
      // }
    },
    // cutout: '50%', // Ensure this is the same for all doughnut-type charts
    // maintainAspectRatio: false // This can help with making the chart responsive
  };

  return (
    <div className="stats-container">
      {showCachedIcon && (
        <>
          <Tooltip target=".cache-notification-icon" />

          <i
            className="cache-notification-icon pi pi-info-circle "
            data-pr-tooltip={cacheErrorMessage}
            style={{ fontSize: '1.2rem', cursor: 'pointer' }}
          ></i>
        </>
      )}

      <Chart type="doughnut" data={donutData} options={options} />
    </div>
  );
};

export default memo(QuestionLanguageCard);

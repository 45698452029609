import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InterviewItem } from '../../Types/InterviewItem';
import { Checkbox } from 'primereact/checkbox';
import { useState } from 'react';

import { Button } from 'primereact/button';
import InterviewNotes from './InterviewNotes';

type Props = {
  interviewData: InterviewItem[];
  removeOldInterview: (item: InterviewItem) => void;
};

const PrevInterviews = ({ interviewData, removeOldInterview }: Props) => {
  const [selectedInterview, setSelectedInterview] = useState<InterviewItem>(); // for notes

  const body = (rowData: InterviewItem) => {
    const formattedDate =
      rowData.dateCompletion?.toLocaleDateString() ?? 'error'; // Convert Date to string in your preferred format
    return <span>{formattedDate}</span>;
  };

  const checkboxTemplate = (rowDataRemove: InterviewItem) => {
    return (
      <Checkbox
        onChange={() => removeOldInterview(rowDataRemove)}
        checked={false}
      ></Checkbox>
    );
  };

  const editButton = (rowData: InterviewItem) => {
    return (
      <Button
        onClick={() => setSelectedInterview(rowData)}
        style={{ border: 'none', borderRadius: '100px' }}
        icon="pi pi-pencil"
      />
    );
  };

  return (
    <div className="widget prevInterviews gray">
      {selectedInterview != undefined && (
        <>
          <InterviewNotes
            interview={selectedInterview}
            interviewData={interviewData}
          />
          <Button
            onClick={() => setSelectedInterview(undefined)}
            style={{
              marginTop: '1rem',
              textAlign: 'center',
              borderColor: '#FCA311',
            }}
          >
            Close Note
          </Button>
        </>
      )}
      <DataTable value={interviewData.filter((item) => item.done)}>
        <Column
          style={{ color: '#FCA311', width: '30%' }}
          field="dateCompletion"
          header="Completed"
          body={body}
        ></Column>

        <Column field="company" header="Company"></Column>
        <Column body={editButton}></Column>

        <Column
          header="Remove"
          style={{ width: '10%', textAlign: 'center' }}
          className="checkbox-col"
          field="done"
          body={checkboxTemplate}
        ></Column>
      </DataTable>
    </div>
  );
};

export default PrevInterviews;

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

type Props = {
  setGoal: (e: number) => void;
};

const QuestionDifficultySettings = ({ setGoal }: Props) => {
  const [localGoal, setLocalGoal] = useState('');

  const handleGoalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalGoal(e.target.value);
  };

  const handleConfirmClick = () => {
    setGoal(+localGoal);
    localStorage.setItem('questionDifficultyGoal', localGoal);
  };
  const handleResetClick = () => {
    setGoal(-1);
    localStorage.setItem('questionDifficultyGoal', '-1');
  };

  return (
    <div className="component">
      <h3>Enter your total question goal below:</h3>
      <div className="p-inputgroup">
        <InputText
          keyfilter="int"
          value={localGoal}
          onChange={handleGoalChange}
          placeholder=""
        />
        <Button onClick={handleConfirmClick}>Confirm</Button>
        <Button onClick={handleResetClick}>Reset</Button>
      </div>
    </div>
  );
};

export default QuestionDifficultySettings;

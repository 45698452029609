const chromeStoreLink = "https://chromewebstore.google.com/u/2/detail/leettab/jmpkpppaflhbbghfbdbcabgjkfmemklo?hl=en"
const firefoxStoreLink = "https://addons.mozilla.org/en-GB/firefox/addon/leettab/"

const ExtensionCard = () => {
  return (
    <div>
      <h4>Links</h4>
      <ul>
        <li><a style={{ color: '#FCA311', textAlign: 'start' }} href={chromeStoreLink}>Install LeetTab extension on Chrome</a></li>
        <li><a style={{ color: '#FCA311', textAlign: 'start' }} href={firefoxStoreLink}>Install LeetTab extension on Firefox</a></li>
      </ul>
      <h4>Homepage</h4>
      <p>LeetTab will automatically be set as your homepage in Chrome, but to do it in <strong>Firefox</strong> you have to follow the steps below: </p>
      <ul>
        <li>Open a new Firefox window.</li>
        <li>Open Firefox Preferences and open a new tab (LeetTab).</li>
        <li> In the preferences, under &quot;Home&quot;, change &quot;Homepage and new windows&quot; to &quot;Custom URLs...&quot;.</li>
        <li> Click &quot;Use Current Page&quot;.</li>
      </ul>


    </div>
  )
}

export default ExtensionCard
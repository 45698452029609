import { useEffect, useState } from 'react';
import fetchAllUserSubmissions from './UserSubmission';
import { SubmissionResponse } from '../../Types/Submissions';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AddLeaderboardUser from './AddLeaderboardUser';
import { ChipsChangeEvent } from 'primereact/chips';
import LoadingCard from '../LoadingCard/LoadingCard';
import { getCache, setCache } from '../../utils/CacheLogic';
import LeetcodeErrorCard from '../LeetcodeErrorCard/LeetcodeErrorCard';
import { LeaderboardType } from '../../Types/LocalStorageCache';
import { Tooltip } from 'primereact/tooltip';

const COMPONENT_KEY = 'LeaderboardsCache';

type LeaderboardElement = {
  username: string;
  numberOfSubmissions: number; // since last n days
};

const DAY_THRESHOLD = 7;

const Leaderboard = () => {
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const [visible, setVisible] = useState<boolean>(false); // for the add dialog
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardElement[]>(
    [],
  );
  const [usernames, setUsernames] = useState<string[]>([]);
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showCachedIcon, setShowCachedIcon] = useState<boolean>(false); // Variable doesn't work
  const [cacheErrorMessage, setCacheErrorMessage] = useState<string>(''); // Variable doesn't work


  const setUsernamesDirect = (usernames: string[]) => {
    // all changes to set interview will automatically change storage
    setUsernames(usernames);
    localStorage.setItem('leaderboardUsernames', JSON.stringify(usernames));
  };

  useEffect(() => {
    const checkLocalStorage = () => {
      let storedUsernames: string[] = [];
      try {
        storedUsernames = JSON.parse(
          localStorage.getItem('leaderboardUsernames') ?? 'null',
        );
      } catch {
        // corrupted storage
        localStorage.removeItem('leaderboardUsernames');
        storedUsernames = JSON.parse('null');
      }
      if (storedUsernames == null) {
        setUsernamesDirect([]);
      } else {
        setUsernamesDirect(storedUsernames);
      }
    };
    checkLocalStorage();
  }, []);

  useEffect(() => {
    const getAllUsersSubmissions = async () => {
      try {
        setIsLoading(true);
        const response: SubmissionResponse[] =
          await fetchAllUserSubmissions(usernames);

        const currentUnixTime = new Date().getTime() / 1000; // in seconds
        const unixTimeInDay = 86400;
        const dayThresholdUnix =
          currentUnixTime - unixTimeInDay * DAY_THRESHOLD;

        const newLeaderboardData: LeaderboardElement[] = response.map(
          (elem) => {
            const submissionCount = elem.recentSubmissions.filter(
              (submission) => Number(submission.timestamp) > dayThresholdUnix,
            ).length;
            return {
              username: elem.username,
              numberOfSubmissions: submissionCount,
            };
          },
        );

        if (newLeaderboardData.length == 0) {
          setIsLoading(false);
          throw Error("couldn't fetch data");
        }
        setShowCachedIcon(false);
        setLeaderboardData(newLeaderboardData);
        // save it in cache in newLeaderboardData form
        const leaderboardCacheObject = {
          elements: newLeaderboardData,
        };

        setCache(COMPONENT_KEY, leaderboardCacheObject);

        setIsLoading(false);
      } catch (error) {
        // retrieve from cache
        const newLeaderboardData: LeaderboardType = getCache(COMPONENT_KEY);

        if (newLeaderboardData == null) {
          return <LeetcodeErrorCard errorType="networkError" />;
        }

        // Deal with the notification logic
        setShowCachedIcon(true);
        if (newLeaderboardData.timestamp) {
          setCacheErrorMessage(
            `This was loaded from cache at ${new Date(
              newLeaderboardData.timestamp ?? '',
            ).toISOString()}, due to current network errors`,
          );
        } else {
          setCacheErrorMessage(
            'This was loaded from cache due to current network errors',
          );
        }

        setLeaderboardData(newLeaderboardData.elements);
        setIsLoading(false);
      }
    };
    getAllUsersSubmissions();
  }, [usernames, showCachedIcon]);

  if (isLoading) {
    return <LoadingCard />;
  }

  // this doesn't work now, remove through component settings
  // const checkboxTemplate = (elem: LeaderboardElement) => {
  //   return (
  //     <Button icon="pi pi-times" onClick={() => (setUsernames(usernames.filter((username) => username !== elem.username)))} text />
  //   );
  // };

  const handleAddUsernames = (e: ChipsChangeEvent) => {
    const newValues = e.value || [];

    if (newValues.length > 4) {
      setErrorMessage('You can have 4 usernames at most!');
      setErrorVisible(true);
      newValues.pop();
    } else {
      setErrorVisible(false);
    }
    setUsernamesDirect(newValues);
  };

  return (
    <div className="stats-container">
      <Button
        severity="warning"
        rounded
        text
        className="addUserToLeaderboardButton"
        icon="pi pi-plus"
        onClick={() => setVisible(true)}
      />

      {showCachedIcon && (
        <>
          <Tooltip target=".cache-notification-icon" />

          <i
            className="cache-notification-icon pi pi-info-circle "
            data-pr-tooltip={cacheErrorMessage}
            style={{ fontSize: '1.2rem', cursor: 'pointer' }}
          ></i>
        </>
      )}

      <div
        className="customTable"
        style={{ width: '100%', backgroundColor: '#282828' }}
      >
        <DataTable
          scrollable
          scrollHeight="250px"
          value={leaderboardData.slice().sort((a, b) => {
            return b.numberOfSubmissions - a.numberOfSubmissions;
          })}
        >
          {/* <Column className='checkbox-col' field='done' body={checkboxTemplate}></Column> */}
          <Column
            style={{ color: '#FCA311' }}
            field="username"
            header="Username"
            body={(rowData: LeaderboardElement) => rowData.username}
          ></Column>
          <Column
            field="numberOfSubmissions"
            header="Questions Solved (last 7 days)"
          ></Column>
        </DataTable>

        <Dialog
          header="Add usernames"
          visible={visible}
          style={{ width: '400px' }}
          onHide={() => setVisible(false)}
        >
          <AddLeaderboardUser
            usernames={usernames}
            handleAddUsernames={handleAddUsernames}
          />
          {errorVisible && (
            <p style={{ textAlign: 'center' }}>{errorMessage}</p>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default Leaderboard;

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import AddInterview from './AddInterview';
import { InterviewItem } from '../../Types/InterviewItem';
import PrevInterviews from './PrevInterviews';
import InterviewNotes from './InterviewNotes';

const OneDayInMS: number = 1000 * 60 * 60 * 24;

const CurrentInterviews = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [prevVisible, setPrevVisible] = useState<boolean>(false);
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [noteVisible, setNoteVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [interviewData, setInterviewData] = useState<InterviewItem[]>([]);
  const [justExpiredInterviews, setJustExpiredInterviews] = useState<
    InterviewItem[]
  >([]);
  const [selectedInterview, setSelectedInterview] = useState<InterviewItem>();

  const setInterviewDataDirect = (newVal: InterviewItem[]) => {
    // all changes to set interview will automatically change storage
    setInterviewData(newVal);
    localStorage.setItem('interviews', JSON.stringify(newVal));
  };

  useEffect(() => {
    const checkLocalStorage = () => {
      let storedInterviews: InterviewItem[] = [];
      try {
        storedInterviews = JSON.parse(
          localStorage.getItem('interviews') ?? 'null',
        );
      } catch {
        // corrupted storage
        localStorage.removeItem('interviews');
        storedInterviews = JSON.parse('null');
      }
      if (storedInterviews == null) {
        setInterviewDataDirect([]);
      } else {
        // setInterviewDataDirect(LoadData(storedInterviews, false));
        setInterviewDataDirect(LoadData(storedInterviews, false));
      }
    };
    checkLocalStorage();
  }, []);
  // checks if local storage has interview data, if so sets it else sets it to []

  // Proceeses data recieved from local storage
  const LoadData = (data: InterviewItem[], sortData: boolean) => {
    const justExpired: InterviewItem[] = [];
    const newData: InterviewItem[] = [];
    const currentDate: Date = new Date();
    data.forEach((item) => {
      item.dateCompletion = new Date(item.dateCompletion); // re objectify the object
      const timeDifferenceMs: number =
        item.dateCompletion.getTime() - currentDate.getTime();
      item.daysLeft = Math.ceil(timeDifferenceMs / OneDayInMS); // converting from ms to days
      if (item.daysLeft <= 0 && !item.done) {
        item.done = true;
        justExpired.push(item);
      }
      newData.push(item);
    });

    if (sortData) {
      newData.sort((a, b) => {
        return (a.daysLeft ?? 0) - (b.daysLeft ?? 0);
      });
    }

    if (justExpired.length > 0) {
      setJustExpiredInterviews(justExpired);
    }
    return newData;
  };

  const completeInterviewItem = (rowData: InterviewItem) => {
    const index = interviewData.findIndex((item) => rowData === item);
    if (index !== -1) {
      const updatedData = [...interviewData];
      updatedData.splice(index, 1);
      rowData.done = true;
      rowData.dateCompletion = new Date();
      updatedData.push(rowData);
      setInterviewDataDirect(updatedData);
    }
  };

  const addInterview = (companyName: string, deadline: Date, link = '') => {
    if (deadline < new Date()) {
      setErrorMessage('Cannot set a deadline in the past');
      setErrorVisible(true);
      return;
    }

    if (companyName === '') {
      setErrorMessage('Company name cannot be empty');
      setErrorVisible(true);
      return;
    }

    if (link != '') {
      try {
        new URL(link);
      } catch (error) {
        setErrorMessage('The URL provided is not valid.');
        setErrorVisible(true);
        return;
      }
    }


    setErrorVisible(false);
    const newData: InterviewItem = {
      done: false,
      dateCompletion: deadline,
      company: companyName,
      notes: '',

      link: link,
    };
    setInterviewDataDirect(LoadData([...interviewData, newData], true));
    setVisible((prev) => !prev);
  };

  const checkboxTemplate = (rowData: InterviewItem) => {
    return (
      <Checkbox
        onChange={() => completeInterviewItem(rowData)}
        checked={rowData.done}
      ></Checkbox>
    );
  };

  const removeOldInterview = (rowData: InterviewItem) => {
    const index = interviewData.findIndex((item) => item === rowData);
    if (index !== -1) {
      const updatedData = [...interviewData];
      updatedData.splice(index, 1);
      setInterviewDataDirect(updatedData);
    }
  };

  const editButton = (rowData: InterviewItem) => {
    return (
      <Button
        onClick={() => {
          setSelectedInterview(rowData);
          setNoteVisible(true);
        }}
        style={{ border: 'none', borderRadius: '100px' }}
        icon="pi pi-pencil"
      />
    );
  };

  const companyName = (rowData: InterviewItem) => {
    if (rowData.link == '') {
      return <div style={{ color: '#fca311' }}>{rowData.company}</div>;
    }
    return (
      <a
        style={{ display: 'inline', color: '#fca311' }}
        target="_blank"
        rel="noopener noreferrer"
        href={rowData.link}
      >
        {rowData.company}
      </a>
    );
  };

  return (
    <div
      className="widget"
      style={{ backgroundColor: '#282828', maxWidth: '1200px', margin: 'auto' }}
    >
      <div className="cur-buttons">
        <Button
          severity="warning"
          rounded
          text
          className="add-button"
          icon="pi pi-plus"
          onClick={() => setVisible(true)}
        />
      </div>
      <DataTable
        style={{ backgroundColor: '#282828' }}
        className="customDataTableStyle" // Add this line to utilize a CSS class if preferred
        scrollable
        scrollHeight="250px"
        value={interviewData.filter((item) => !item.done)}
      >
        <Column
          style={{ width: '15%' }}
          className="checkbox-col"
          field="done"
          body={checkboxTemplate}
        ></Column>
        <Column
          style={{ color: '#FCA311', fontSize: '1.2em', width: '30%' }}
          field="daysLeft"
          header=""
          body={(rowData) => rowData.daysLeft + ' days left'}
        ></Column>
        <Column
          style={{ color: '#FCA311', fontSize: '1.2em', width: '30%' }}
          field="company"
          header="Company"
          body={companyName}
        ></Column>

        <Column 
        style={{ color: '#FCA311', fontSize: '1.2em' }}
        header="Notes"
        body={editButton}></Column>
      </DataTable>

      <Dialog
        header="Add An Interview"
        visible={visible}
        style={{ width: '500px' }}
        onHide={() => setVisible(false)}
      >
        <AddInterview addInterview={addInterview} />
        {errorVisible && <p style={{ textAlign: 'center' }}>{errorMessage}</p>}
      </Dialog>
      <br />
      <a
        style={{ color: '#FCA311', fontSize: '1.2rem' }}
        onClick={() => setPrevVisible((prev) => !prev)}
        onKeyDown={() => setPrevVisible((prev) => !prev)}
      >
        Previous Interviews
      </a>
      <Dialog
        header="Previous interviews"
        style={{ width: '650px' }}
        visible={prevVisible}
        onHide={() => setPrevVisible(false)}
      >
        <PrevInterviews
          interviewData={interviewData}
          removeOldInterview={removeOldInterview}
        />
      </Dialog>
      <Dialog
        className="edit"
        style={{ width: '500px' }}
        visible={noteVisible}
        onHide={() => setNoteVisible(false)}
      >
        <div style={{ marginTop: '-1rem' }}>
          <InterviewNotes
            interviewData={interviewData}
            interview={
              selectedInterview ?? {
                company: '',
                dateCompletion: new Date(),
                done: false,
                notes: '',
              }
            }
          />
        </div>
      </Dialog>
      {justExpiredInterviews && (
        <Dialog
          header="Interviews"
          style={{ width: '500px' }}
          visible={justExpiredInterviews.length > 0}
          onHide={() => setJustExpiredInterviews([])}
        >
          <p>
            These interviews expired since your last visit and has been moved to
            previous interviews
          </p>
          <ul>
            {justExpiredInterviews.map((item: InterviewItem) => (
              <li key={String(item)}>{item.company}</li>
            ))}
          </ul>
        </Dialog>
      )}
    </div>
  );
};

export default CurrentInterviews;

import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { FileUpload, FileUploadSelectEvent } from 'primereact/fileupload';
import { LocalStorageCache } from '../../Types/LocalStorageCache';
import { Dialog } from 'primereact/dialog';
type Props = {
  username: string;
  setUsername: (username: string) => void;
  startPageMode: boolean;
  setStartPageMode: (startPageMode: boolean) => void;
  topFourComponents: string[];
  setTopFourComponents: (topFourComponents: string[]) => void;
};

const SettingsScreen = ({
  username,
  setUsername,
  startPageMode,
  setStartPageMode,
  topFourComponents,
  setTopFourComponents,
}: Props) => {
  const [localUsername, setLocalUsername] = useState(username);
  const [topLeftComponent, setTopLeftComponent] = useState(
    topFourComponents[0],
  );
  const [topRightComponent, setTopRightComponent] = useState(
    topFourComponents[1],
  );
  const [bottomLeftComponent, setBottomLeftComponent] = useState(
    topFourComponents[2],
  );
  const [bottomRightComponent, setBottomRightComponent] = useState(
    topFourComponents[3],
  );
  const [showImportExportError, setShowImportExportError] =
    useState<boolean>(false);

  useEffect(() => {
    const topFour = [
      topLeftComponent,
      topRightComponent,
      bottomLeftComponent,
      bottomRightComponent,
    ];
    setTopFourComponents(topFour);
    localStorage.setItem('topFour', JSON.stringify(topFour));
  }, [
    topLeftComponent,
    topRightComponent,
    bottomLeftComponent,
    bottomRightComponent,
    setTopFourComponents,
  ]);

  const componentOptions = [
    { name: 'Difficulty', value: 'Difficulty' },
    { name: 'Language', value: 'Language' },
    { name: 'Tags', value: 'Tags' },
    { name: "Today's Question", value: 'TodaysQuestion' },
    { name: 'Leetcode Leaderboard', value: 'Leaderboard' },
    { name: 'Recently Solved', value: 'RecentSubmissions' },
    { name: 'Blank', value: 'None' },
  ];

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalUsername(e.target.value);
  };

  const handleConfirmClick = () => {
    setUsername(localUsername);
    localStorage.setItem('username', localUsername);
  };

  const handleModeChange = (e: InputSwitchChangeEvent) => {
    setStartPageMode(e.value);
    localStorage.setItem('startPageMode', e.value.toString());
  };

  const handleImport = (event: FileUploadSelectEvent) => {
    const file: File = event.files[0];
    if (!file) {
      console.warn('no file');
      return;
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const content: string = arrayBufferToString(e.target?.result ?? '');
      try {
        const parsedFile: LocalStorageCache = JSON.parse(content); // checking if valid json
        const interviews: string = JSON.stringify(parsedFile.interviews);
        const username: string = parsedFile.username;
        const topFour: string = JSON.stringify(parsedFile.topFour);
        const leaderboardUsernames: string = JSON.stringify(
          parsedFile.leaderboardUsernames,
        );
        const questionDifficultyGoal: string = JSON.stringify(
          parsedFile.questionDifficultyGoal,
        );

        localStorage.setItem('interviews', interviews);
        localStorage.setItem('topFour', topFour);
        localStorage.setItem('username', username);
        localStorage.setItem('leaderboardUsernames', leaderboardUsernames);
        localStorage.setItem('questionDifficultyGoal', questionDifficultyGoal);
        window.location.reload();
      } catch (error) {
        console.warn('Invalid JSON file');
        setShowImportExportError(true);
      }
    };
    reader.readAsText(file);
  };

  const arrayBufferToString = (input: string | ArrayBuffer) => {
    if (input instanceof ArrayBuffer) {
      const decoder = new TextDecoder('utf-8');
      return decoder.decode(input);
    } else {
      return input; // If it's not an ArrayBuffer, return the input as is
    }
  };

  const handleExport = () => {
    const interviewData: string = localStorage.getItem('interviews') ?? '[]';
    const usernameData: string = localStorage.getItem('username') ?? 'null';
    const topFourData: string =
      localStorage.getItem('topFour') ??
      "['Difficulty','Tags','Language','TodaysQuestion']";
    const leaderboardUsernames: string =
      localStorage.getItem('leaderboardUsernames') ?? '[]';
    const questionDifficultyGoal: string =
      localStorage.getItem('questionDifficultyGoal') ?? 'null';
    // let localStorageData: LocalStorageCache;
    let localStorageData: unknown;
    try {
      localStorageData = {
        interviews: JSON.parse(interviewData),
        username: usernameData,
        topFour: JSON.parse(topFourData),
        leaderboardUsernames: JSON.parse(leaderboardUsernames),
        questionDifficultyGoal: JSON.parse(questionDifficultyGoal),
      };
    } catch (error) {
      // con only happen if user fucks with local storage
      setShowImportExportError(true);
      return;
    }

    // blob object holds data (specified to json) specified for file downloads
    const jsonBlob: Blob = new Blob([JSON.stringify(localStorageData)], {
      type: 'application/json',
    });

    // creates download link
    const downloadLink: string = URL.createObjectURL(jsonBlob);

    // creates <a> element and adds href , download properties
    const link: HTMLAnchorElement = document.createElement('a');

    const currentDate =
      String(new Date().getFullYear()) +
      '-' +
      String(new Date().getMonth() + 1) +
      '-' +
      String(new Date().getDate());
    const fileName = 'leettab-' + currentDate + '.json';
    link.href = downloadLink;
    link.download = fileName;

    // appends link to document , clicks it, then removes it
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(downloadLink);
    document.body.removeChild(link);
  };

  const handleTopFourChange = (value: string, index: number) => {
    switch (index) {
      case 0:
        setTopLeftComponent(value);
        break;
      case 1:
        setTopRightComponent(value);
        break;
      case 2:
        setBottomLeftComponent(value);
        break;
      case 3:
        setBottomRightComponent(value);
        break;
    }
  };

  return (
    <div className="settingsPage">
      <div className="settingsLeetcode">
        <h3>Leetcode</h3>
        <div className="p-inputgroup">
          <InputText
            value={localUsername}
            onChange={handleUsernameChange}
            placeholder="Leetcode Username"
          />
          <button onClick={handleConfirmClick}>Confirm</button>
        </div>
      </div>
      <Divider align="center" />

      <div className="settingsLayout">
        <h3>Layout</h3>
        <div
          className="mode-switch"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <InputSwitch
            checked={startPageMode}
            onChange={handleModeChange}
            style={{ marginRight: '10px' }}
          />
          <label style={{ cursor: 'pointer' }}>Interview Season</label>
        </div>
        <br />
        <div className="layoutButtons">
          <div className="layoutButtonItem">
            <p>Top Left Component: </p>
            <Dropdown
              value={topLeftComponent}
              onChange={(e) => handleTopFourChange(e.value, 0)}
              options={componentOptions}
              optionLabel="name"
              placeholder="Select Component"
              className="dropdownSelector"
            />
          </div>

          <div className="layoutButtonItem">
            <p>Top Right Component: </p>
            <Dropdown
              value={topRightComponent}
              onChange={(e) => handleTopFourChange(e.value, 1)}
              options={componentOptions}
              optionLabel="name"
              placeholder="Select Component"
              className="dropdownSelector"
            />
          </div>
          <div className="layoutButtonItem">
            <p>Bottom Left Component: </p>
            <Dropdown
              value={bottomLeftComponent}
              onChange={(e) => handleTopFourChange(e.value, 2)}
              options={componentOptions}
              optionLabel="name"
              placeholder="Select Component"
              className="dropdownSelector"
            />
          </div>
          <div className="layoutButtonItem">
            <p>Bottom Right Component: </p>
            <Dropdown
              value={bottomRightComponent}
              onChange={(e) => handleTopFourChange(e.value, 3)}
              options={componentOptions}
              optionLabel="name"
              placeholder="Select Component"
              className="dropdownSelector"
            />
          </div>
        </div>
      </div>

      <Divider align="center" />
      <div className="settingsData">
        <h3>Data</h3>
        <p>Restore</p>
        <FileUpload
          mode="basic"
          accept=".json"
          maxFileSize={1000000}
          onSelect={(e: FileUploadSelectEvent) => handleImport(e)}
          auto
          chooseLabel="Import Data"
        />
        <p>Backup</p>
        <Button
          style={{ width: '57.5%' }}
          label="Export data"
          onClick={() => handleExport()}
        />
        <Button
          label="clear cache"
          style={{ borderColor: 'red', marginTop: '1rem', width: '57.5%' }}
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        />
      </div>

      <Divider align="center" />
      <div>
        <h3>LeetTab v3.2</h3>
        <p>All rights belong to Bora Akyuz and Ramiz Abdulla.</p>
      </div>
      {showImportExportError && (
        <>
          <Dialog
            header="Data import or export error"
            visible={showImportExportError}
            style={{ width: '50vw' }}
            modal
            onHide={() => setShowImportExportError(false)}
          >
            There was an issue exporting or importing your data, the json may be
            invalid
          </Dialog>
          <Button onClick={() => setShowImportExportError(false)}>Close</Button>
        </>
      )}
    </div>
  );
};

export default SettingsScreen;

import { useQuery } from '@apollo/client';
import { USER_PROBLEMS_BY_TAG_QUERY } from './query';
import { Chart } from 'primereact/chart';
import { memo } from 'react';
import LeetcodeErrorCard from '../LeetcodeErrorCard/LeetcodeErrorCard';
import LoadingCard from '../LoadingCard/LoadingCard';
import { Tag, Tags } from '../../Types/LocalStorageCache';
import { getCache, setCache } from '../../utils/CacheLogic';
import { usernameNotFound } from '../../utils/Constants';
import { Tooltip } from 'primereact/tooltip';

type Props = {
  username: string;
};

const COMPONENT_KEY = 'QuestionTags';

const QuestionTagCard = ({ username }: Props) => {
  let showCachedIcon = false; // No need to make this a state since it's only checked in first load.
  let cacheErrorMessage = '';

  // ----- query the data -----
  const { loading, error, data } = useQuery(USER_PROBLEMS_BY_TAG_QUERY, {
    variables: { username: username },
  });
  let questionTag: Tags;
  if (loading) return <LoadingCard />;
  if (error) {
    if (error.message == usernameNotFound) {
      return <LeetcodeErrorCard errorType="usernameNotFound" />;
    }

    questionTag = getCache(COMPONENT_KEY);
    if (questionTag == null) {
      return <LeetcodeErrorCard errorType="networkError" />;
    }

    // Deal with the notification logic
    showCachedIcon = true;
    if (questionTag.timestamp) {
      cacheErrorMessage = `This was loaded from cache at ${new Date(
        questionTag.timestamp ?? '',
      ).toISOString()}, due to current network errors`;
    } else {
      cacheErrorMessage =
        'This was loaded from cache due to current network errors';
    }
  } else {
    const fundamental: Tag[] = data.matchedUser.tagProblemCounts.fundamental;
    const intermediate: Tag[] = data.matchedUser.tagProblemCounts.intermediate;
    const advanced: Tag[] = data.matchedUser.tagProblemCounts.advanced;
    questionTag = {
      tags: flattenResponseDataAndSort(fundamental, intermediate, advanced),
    };
    setCache(COMPONENT_KEY, questionTag);
  }
  // --------------------------

  function flattenResponseDataAndSort(
    fundamental: Tag[],
    intermediate: Tag[],
    advanced: Tag[],
  ) {
    const allTypes: Tag[] = [];

    fundamental.forEach((element: Tag) =>
      allTypes.push({
        tagName: element.tagName,
        problemsSolved: element.problemsSolved,
      }),
    );

    intermediate.forEach((element: Tag) =>
      allTypes.push({
        tagName: element.tagName,
        problemsSolved: element.problemsSolved,
      }),
    );

    advanced.forEach((element: Tag) =>
      allTypes.push({
        tagName: element.tagName,
        problemsSolved: element.problemsSolved,
      }),
    );

    allTypes.sort((a, b) => b.problemsSolved - a.problemsSolved);
    return allTypes;
  }

  const topFiveTags = questionTag.tags.slice(0, 5);
  // Prepare the data for the Pie chart
  const pieData = {
    labels: topFiveTags.map((tag) => tag.tagName),
    datasets: [
      {
        data: topFiveTags.map((tag) => tag.problemsSolved),
        backgroundColor: [
          '#00b8a3',
          '#ffc01e',
          '#ef4743',
          '#9fa1a4',
          '#4a4a4a',
        ],
        hoverBackgroundColor: [
          '#00b8a3',
          '#ffc01e',
          '#ef4743',
          '#9fa1a4',
          '#4a4a4a',
        ],
        borderColor: 'transparent',
        borderWidth: 0,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          color: '#fff', // Example to set text color to white
          font: {
            size: 16, // Set font size here
          },
        },
      },
    },
  };

  return (
    <div className="stats-container">
      {showCachedIcon && (
        <>
          <Tooltip target=".cache-notification-icon" />

          <i
            className="cache-notification-icon pi pi-info-circle "
            data-pr-tooltip={cacheErrorMessage}
            style={{ fontSize: '1.2rem', cursor: 'pointer' }}
          ></i>
        </>
      )}
      <Chart type="pie" data={pieData} options={options} />
    </div>
  );
};
export default memo(QuestionTagCard);

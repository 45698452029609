import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';

const searchEngineMap = {
  google: 'https://www.google.com/search?q=',
  bing: 'https://www.bing.com/search?q=',
  duckduckgo: 'https://duckduckgo.com/?q=',
};

// could potentially take the search engine as a prop
const SearchBarCard = () => {
  const [searchText, setSearchText] = useState<string>('');
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const searchQuery = searchText.replace(' ', '+');
      window.location.href = searchEngineMap.google + searchQuery;
    }
  };
  return (
    <div className="searchBarContainer">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          placeholder="Google Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="customInputText"
          onKeyDown={handleKeyDown}
          style={{
            color: 'white',
          }}
        />
      </span>
    </div>
  );
};

export default SearchBarCard;

import { gql } from '@apollo/client';

export const USER_PROBLEM_BY_LANGUAGE = gql`
  query languageStats($username: String!) {
    matchedUser(username: $username) {
      languageProblemCount {
        languageName
        problemsSolved
      }
    }
  }
`;

import { Chips, ChipsChangeEvent } from 'primereact/chips';

type Props = {
  usernames: string[];
  handleAddUsernames: (e: ChipsChangeEvent) => void;
};

const AddLeaderboardUser = ({ usernames, handleAddUsernames }: Props) => {
  return (
    <div className="component">
      <h3>Enter the leetcode usernames below.</h3>
      <Chips
        style={{ width: '100%', justifyContent: 'center' }}
        value={usernames}
        onChange={handleAddUsernames}
        className="chipsContainer"
      />
    </div>
  );
};

export default AddLeaderboardUser;

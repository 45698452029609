// Import necessary modules
import { GET_RECENT_USER_SUBMISSIONS } from './query';
import client from '../../apolloClient';
import { SubmissionResponse } from '../../Types/Submissions';


// Function to fetch user submissions
const fetchUserSubmissions = async (
  username: string,
  limit: number,
): Promise<any> => {
  // Fetch data using Apollo Client
  const { error, data } = await client.query({
    query: GET_RECENT_USER_SUBMISSIONS,
    variables: { username, limit },
  });
  if (error || data == null || data == undefined) {
    throw new Error('we are over');
  } else {
    return data;
  }
};

// fetchAllUserSubmissions
const fetchAllUserSubmissions = async (
  usernames: string[],
): Promise<SubmissionResponse[]> => {
  const promises = usernames.map((username) =>
    fetchUserSubmissions(username, 20),
  );
  const responses = await Promise.all(promises);
  return responses.map((response, index) => ({
    recentSubmissions: response.recentAcSubmissionList || [], // Add a fallback for undefined
    username: usernames[index],
  }));
};


export default fetchAllUserSubmissions
import { ChangeEvent, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

type Props = {
  addInterview: (companyName: string, deadline: Date, link: string) => void;
};

const curDate = new Date();
curDate.setDate(curDate.getDate() + 1);
const AddInterview = ({ addInterview }: Props) => {
  const [companyName, setCompanyName] = useState<string>('');
  const [deadline, setDeadline] = useState<Date>(curDate);
  const [link, setLink] = useState<string>('');

  return (
    <div className="component">
      <div className="add-new-interview">
        <h3>Company Name</h3>
        <InputText
          value={companyName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setCompanyName(e.target.value)
          }
          maxLength={30}
        ></InputText>
        <h3 style={{ marginBottom: 0 }}>Link to assessment (optional)</h3>
        <i style={{ color: 'gray', marginBottom: '1rem' }}>
          company name will be a hyperlink to this link
        </i>
        <InputText
          value={link}
          placeholder={'https://www.website.com'}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLink(e.target.value)
          }
        />
        <h3>Deadline</h3>
        <Calendar
          style={{ marginBottom: '2rem' }}
          value={deadline}
          onChange={(e) => setDeadline(e.value ?? new Date())}
        />

        <Button
          style={{ justifyContent: 'center',  }}
          onClick={() => addInterview(companyName, deadline, link)}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default AddInterview;

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';

type Props = {
  username: string;
  setUsername: (newVal: string) => void;
};

const NewUserCard = ({ username, setUsername }: Props) => {
  const [localUsername, setLocalUsername] = useState(username);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalUsername(e.target.value);
  };

  const handleConfirmClick = () => {
    setUsername(localUsername);
    localStorage.setItem('username', localUsername);
  };

  return (
    <div>
      <p>Enter leetcode username to use the leetcode components</p>
      <div
        className="p-inputgroup"
        style={{ margin: 'auto', width: 'fit-content' }}
      >
        <InputText
          style={{ maxWidth: '350px' }}
          value={localUsername}
          onChange={handleUsernameChange}
          placeholder="Leetcode Username"
        />
        <Button
          style={{ backgroundColor: '#FCA311', border: 'none'}}
          onClick={handleConfirmClick}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default NewUserCard;

import { memo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_RECENT_USER_SUBMISSIONS } from './query';
import LeetcodeErrorCard from '../LeetcodeErrorCard/LeetcodeErrorCard';
import { Submission } from '../../Types/Submissions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoadingCard from '../LoadingCard/LoadingCard';
import { RecentlySolved } from '../../Types/LocalStorageCache';
import { getCache, setCache } from '../../utils/CacheLogic';
import { usernameNotFoundAlt } from '../../utils/Constants';
import { Tooltip } from 'primereact/tooltip';

type Props = {
  username: string;
};

const COMPONENT_KEY = 'RecentSubmissions';

const RecentSubmissionsCard = ({ username }: Props) => {
  let showCachedIcon = false; // No need to make this a state since it's only checked in first load.
  let cacheErrorMessage = '';
  // ----- query the data -----
  const { loading, error, data } = useQuery(GET_RECENT_USER_SUBMISSIONS, {
    variables: { username: username, limit: 5 },
  });
  let recentAcSubmissionList: RecentlySolved;
  if (loading) return <LoadingCard />;

  if (error) {
    if (error.message == usernameNotFoundAlt) {
      return <LeetcodeErrorCard errorType="usernameNotFound" />;
    }
    console.log('from recent submissions', error.message);
    recentAcSubmissionList = getCache(COMPONENT_KEY);

    if (recentAcSubmissionList == null) {
      return <LeetcodeErrorCard errorType="networkError" />;
    }

    // Deal with the notification logic
    showCachedIcon = true;
    if (recentAcSubmissionList.timestamp) {
      cacheErrorMessage = `This was loaded from cache at ${new Date(
        recentAcSubmissionList.timestamp ?? '',
      ).toISOString()}, due to current network errors`;
    } else {
      cacheErrorMessage =
        'This was loaded from cache due to current network errors';
    }
  } else {
    recentAcSubmissionList = {
      solved: data.recentAcSubmissionList,
    };
    setCache(COMPONENT_KEY, recentAcSubmissionList);
  }
  // --------------------------

  const problemRow = (rowData: Submission) => {
    const questionLink =
      'https://leetcode.com/problems/' + rowData.titleSlug + '/description/';
    return (
      <a
        href={questionLink}
        className="problem-link"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#fff', fontSize: '16px' }}
      >
        {rowData.title}
      </a>
    );
  };

  return (
    <div className="stats-container">
      {showCachedIcon && (
        <>
          <Tooltip target=".cache-notification-icon" />

          <i
            className="cache-notification-icon pi pi-info-circle "
            data-pr-tooltip={cacheErrorMessage}
            style={{ fontSize: '1.2rem', cursor: 'pointer' }}
          ></i>
        </>
      )}
      <div
        className="customTable"
        style={{ width: '100%', backgroundColor: '#282828' }}
      >
        <DataTable
          value={recentAcSubmissionList.solved}
          scrollable
          scrollHeight="230px"
        >
          <Column
            style={{ color: '#fff' }}
            field="title"
            header="Last 5 Solved Questions"
            body={problemRow}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default memo(RecentSubmissionsCard);

import React, { memo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { USER_PROBLEMS_SOLVED_QUERY } from './query';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import styles
import { ProgressBar } from 'primereact/progressbar';
import 'primereact/resources/themes/saga-blue/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import LeetcodeErrorCard from '../LeetcodeErrorCard/LeetcodeErrorCard';
import LoadingCard from '../LoadingCard/LoadingCard';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import QuestionDifficultySettings from './QuestionDifficultySettings';
import { QuestionDifficulty } from '../../Types/LocalStorageCache';
import { getCache, setCache } from '../../utils/CacheLogic';
import { usernameNotFound } from '../../utils/Constants';
import { Tooltip } from 'primereact/tooltip';

type Props = {
  username: string;
};

type StyledNumbers = {
  done: number;
  total: number;
};

const COMPONENT_KEY = 'QuestionDifficulty';

const QuestionDifficultyCard = ({ username }: Props) => {
  // ----- query the data -----
  const [visible, setVisible] = useState<boolean>(false); // for the add dialog
  const [goal, setGoal] = useState<number>(-1);

  let showCachedIcon = false; // No need to make this a state since it's only checked in first load.
  let cacheErrorMessage = '';

  useEffect(() => {
    const checkLocalStorage = () => {
      let storedGoal = '';
      try {
        storedGoal = JSON.parse(
          localStorage.getItem('questionDifficultyGoal') ?? 'null',
        );
      } catch {
        // corrupted storage
        localStorage.removeItem('questionDifficultyGoal');
        storedGoal = JSON.parse('null');
      }
      if (storedGoal == null) {
        setGoal(-1);
      } else {
        setGoal(+storedGoal);
      }
    };
    checkLocalStorage();
  }, []);

  const { loading, error, data } = useQuery(USER_PROBLEMS_SOLVED_QUERY, {
    variables: { username: username },
  });
  let questionObject: QuestionDifficulty;

  if (loading) return <LoadingCard />;
  if (error) {
    // check cache exists, if so use it

    if (error.message == usernameNotFound) {
      return <LeetcodeErrorCard errorType="usernameNotFound" />;
    }

    questionObject = getCache(COMPONENT_KEY);
    if (questionObject == null) {
      return <LeetcodeErrorCard errorType="networkError" />; // no cache exists display error
    }

    // Deal with the notification logic
    showCachedIcon = true;
    if (questionObject.timestamp) {
      cacheErrorMessage = `This was loaded from cache at ${new Date(
        questionObject.timestamp ?? '',
      ).toISOString()}, due to current network errors`;
    } else {
      cacheErrorMessage =
        'This was loaded from cache due to current network errors';
    }
  } else {
    // destructure response into varialbes
    const [
      { count: totalDone },
      { count: easyDone },
      { count: mediumDone },
      { count: hardDone },
    ] = data.matchedUser.submitStatsGlobal.acSubmissionNum;

    const [
      { count: totalQuestions },
      { count: easyQuestions },
      { count: mediumQuestions },
      { count: hardQuestions },
    ] = data.allQuestionsCount;

    const [
      { percentage: beatsEasyPercentage },
      { percentage: beatsMediumPercentage },
      { percentage: beatsHardPercentage },
    ] = data.matchedUser.problemsSolvedBeatsStats;
    // create the questionObject
    questionObject = {
      totalDone: totalDone,
      totalQuestions: totalQuestions,
      easyDone: easyDone,
      easyQuestions: easyQuestions,
      mediumDone: mediumDone,
      mediumQuestions: mediumQuestions,
      hardDone: hardDone,
      hardQuestions: hardQuestions,
      beatsEasy: beatsEasyPercentage,
      beatsMedium: beatsMediumPercentage,
      beatsHard: beatsHardPercentage,
    };

    // cache questionObject
    setCache(COMPONENT_KEY, questionObject);
  }
  // -------------------------

  let totalPercentage =
    (questionObject.totalDone / questionObject.totalQuestions) * 100;
  if (goal != undefined && goal != -1) {
    totalPercentage = (questionObject.totalDone / goal) * 100;
  }
  const easyPercentage =
    (questionObject.easyDone / questionObject.easyQuestions) * 100;
  const mediumPercentage =
    (questionObject.mediumDone / questionObject.mediumQuestions) * 100;
  const hardPercentage =
    (questionObject.hardDone / questionObject.hardQuestions) * 100;

  const StyledNumbers = ({ done, total }: StyledNumbers) => (
    <>
      <span
        style={{ fontSize: '1.2rem', color: '#fff', verticalAlign: 'baseline' }}
      >
        {done}
      </span>
      <span
        style={{ fontSize: '0.8rem', color: '#9fa1a4', verticalAlign: 'super' }}
      >
        /{total}
      </span>
    </>
  );

  return (
    <div
      className="stats-container"
      style={{
        justifyContent: 'space-around',
        flexDirection: 'row', // Make sure this is set to row for a horizontal layout
      }}
    >
      <Button
        severity="warning"
        rounded
        text
        className="componentSettingsButton"
        icon="pi pi-cog"
        onClick={() => setVisible(true)}
      />

      {showCachedIcon && (
        <>
          <Tooltip target=".cache-notification-icon" />

          <i
            className="cache-notification-icon pi pi-info-circle "
            data-pr-tooltip={cacheErrorMessage}
            style={{ fontSize: '1.2rem', cursor: 'pointer' }}
          ></i>
        </>
      )}

      {/* Separate container for the circular progress bar */}
      <div
        className="circular-progress-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '150px', // Increased size
          height: '100px', // Increased size
        }}
      >
        <CircularProgressbarWithChildren
          value={totalPercentage}
          styles={buildStyles({
            textColor: '#fff',
            pathColor: '#ffa116',
            trailColor: '#4a4a4a',
            pathTransitionDuration: 0.5,
            textSize: '16px', // Adjust font size if needed
          })}
        >
          <div
            style={{ fontSize: '35px', color: '#fffff', textAlign: 'center' }}
          >
            {' '}
            {/* Adjusted font size */}
            {questionObject.totalDone}
            <div
              style={{ fontSize: '20px', marginTop: '5px', color: '#9fa1a4' }}
            >
              Solved
            </div>
          </div>
        </CircularProgressbarWithChildren>
        {goal != -1 ? (
          <p
            style={{
              position: 'absolute',
              bottom: '0px',
              color: '#9fa1a4',
              fontSize: '16px',
            }}
          >
            Goal: <b>{goal}</b>
          </p>
        ) : (
          <p style={{ position: 'absolute', bottom: '0px' }}></p>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column', // Stack the progress bars vertically
          justifyContent: 'flex-start', // Align the start of the progress bars with the top of the circular progress bar
          height: '100%', // Take full height of the container to align the items // Adjust the width to fill the container minus the width of the circular progress bar
        }}
      >
        {/* Separate container for the horizontal progress bars */}
        <div
          className="horizontal-progress-bars"
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            marginLeft: '20px',
          }}
        >
          {/* Adjusted style for the "Easy" progress bar section */}
          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#9fa1a4',
                marginBottom: '4px',
                fontSize: '1rem',
              }}
            >
              <span>
                Easy{' '}
                <StyledNumbers
                  done={questionObject.easyDone}
                  total={questionObject.easyQuestions}
                />
              </span>
              <span>Beats {questionObject.beatsEasy}%</span>
            </span>
            <ProgressBar
              value={easyPercentage}
              color="#00b8a3"
              style={{
                width: '100%',
                height: '0.6rem',
                backgroundColor: '#294d35',
              }}
              showValue={false}
            ></ProgressBar>
          </div>
          {/* Adjusted style for the "Medium" progress bar section */}
          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#9fa1a4',
                marginBottom: '4px',
                fontSize: '1rem',
              }}
            >
              <span>
                Medium{' '}
                <StyledNumbers
                  done={questionObject.mediumDone}
                  total={questionObject.mediumQuestions}
                />{' '}
              </span>
              <span>Beats {questionObject.beatsMedium}%</span>
            </span>
            <ProgressBar
              value={mediumPercentage}
              color="#ffc01e"
              style={{
                width: '100%',
                height: '0.6rem',
                backgroundColor: '#5e4e25',
              }}
              showValue={false}
            ></ProgressBar>
          </div>
          {/* Adjusted style for the "Hard" progress bar section */}
          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#9fa1a4',
                marginBottom: '4px',
                fontSize: '1rem',
              }}
            >
              <span>
                Hard{' '}
                <StyledNumbers
                  done={questionObject.hardDone}
                  total={questionObject.hardQuestions}
                />{' '}
              </span>
              <span>Beats {questionObject.beatsHard}%</span>
            </span>
            <ProgressBar
              value={hardPercentage}
              color="#ef4743"
              style={{
                width: '100%',
                height: '0.6rem',
                backgroundColor: '#5a302f',
              }}
              showValue={false}
            ></ProgressBar>
          </div>
        </div>
        <Dialog
          header="Component Settings"
          visible={visible}
          style={{ width: '400px' }}
          onHide={() => setVisible(false)}
        >
          <QuestionDifficultySettings setGoal={setGoal} />
          {/* {errorVisible && (
            <p style={{ textAlign: 'center' }}>{errorMessage}</p>
          )} */}
        </Dialog>
      </div>
    </div>
  );
};

export default memo(QuestionDifficultyCard);

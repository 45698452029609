import { gql } from '@apollo/client';

export const TODAYS_QUESTION = gql`
  query questionOfToday {
    activeDailyCodingChallengeQuestion {
      link
      question {
        title
        titleSlug
        topicTags {
          name
          id
          slug
        }
      }
    }
  }
`;

import { ComponentCache } from '../Types/LocalStorageCache';

export const getCache = (componentKey: string) => {
  return JSON.parse(localStorage.getItem(componentKey) ?? 'null');
};

export const setCache = (componentKey: string, value: ComponentCache) => {
  value.timestamp = new Date().getTime();
  const stringifiedValue: string = JSON.stringify(value);
  localStorage.setItem(componentKey, stringifiedValue);
};

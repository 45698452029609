import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './apolloClient';

import { PrimeReactProvider } from 'primereact/api';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <PrimeReactProvider>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </PrimeReactProvider>
  </React.StrictMode>,
);

import { gql } from '@apollo/client';

export const GET_RECENT_USER_SUBMISSIONS = gql`
  query recentAcSubmissions($username: String!, $limit: Int!) {
    recentAcSubmissionList(username: $username, limit: $limit) {
      id
      title
      titleSlug
      timestamp
    }
  }
`;

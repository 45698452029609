import React, { useEffect, useState } from 'react';

const DAYS_ARRAY: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const MONTHS_ARRAY: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DateTimeCard = () => {
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(time), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // tuesday 12th december
  const unixToDate = () => {
    const date: Date = new Date(); // Convert to milliseconds
    const day: string = DAYS_ARRAY[date.getDay()];
    const day_number: number = date.getDate();
    const month: string = MONTHS_ARRAY[date.getMonth()];
    return day + ' ' + day_number + nth(day_number) + ' ' + month;
  };

  // const unixToTime = () => {
  //   const date = new Date(); // Convert to milliseconds
  //   const hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
  //   const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  //   return hours + ":" + minutes + " ";
  // }

  const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  return (
    // needs to be designed
    <div className="dateTimeContainer">
      <h2>
        {/* {unixToTime()} */}
        {unixToDate()}
      </h2>
    </div>
  );
};

export default DateTimeCard;

import { useQuery } from '@apollo/client';
import { TODAYS_QUESTION } from './query';
import { memo } from 'react';
import LeetcodeErrorCard from '../LeetcodeErrorCard/LeetcodeErrorCard';
import LoadingCard from '../LoadingCard/LoadingCard';
import { TodaysDaily } from '../../Types/LocalStorageCache';
import { getCache, setCache } from '../../utils/CacheLogic';
import { Tooltip } from 'primereact/tooltip';

const COMPONENT_KEY = 'TodaysQuestion';

const TodaysQuestionCard = () => {
  let showCachedIcon = false; // No need to make this a state since it's only checked in first load.
  let cacheErrorMessage = '';

  // ----- query the data -----
  const { loading, error, data } = useQuery(TODAYS_QUESTION);
  let dailyQuestion: TodaysDaily;

  if (loading) return <LoadingCard />;

  if (error) {
    dailyQuestion = getCache(COMPONENT_KEY);

    if (dailyQuestion == null) {
      return <LeetcodeErrorCard errorType="networkError" />;
    }
    dailyQuestion.link = 'https://leetcode.com' + dailyQuestion.link;

    // Deal with the notification logic
    showCachedIcon = true;
    if (dailyQuestion.timestamp) {
      cacheErrorMessage = `This was loaded from cache at ${new Date(
        dailyQuestion.timestamp ?? '',
      ).toISOString()}, due to current network errors`;
    } else {
      cacheErrorMessage =
        'This was loaded from cache due to current network errors';
    }
  } else {
    const SHORTCUT = data.activeDailyCodingChallengeQuestion;

    dailyQuestion = {
      title: SHORTCUT.question.title,
      link: SHORTCUT.link,
    };

    setCache(COMPONENT_KEY, dailyQuestion);
  }
  // --------------------------

  return (
    <div className="stats-container" style={{ justifyContent: 'flex-start' }}>
      {showCachedIcon && (
        <>
          <Tooltip target=".cache-notification-icon" />

          <i
            className="cache-notification-icon pi pi-info-circle "
            data-pr-tooltip={cacheErrorMessage}
            style={{ fontSize: '1.2rem', cursor: 'pointer' }}
          ></i>
        </>
      )}
      <div style={{ height: '40%' }}>
        <p
          style={{
            fontSize: '0.8em',
            color: 'gray',
          }}
        >
          {"Today's Daily Challenge:"}
        </p>
      </div>

      <a
        href={dailyQuestion.link}
        style={{
          fontSize: '1.5em',
          textDecoration: 'none',
          color: 'white',
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.textDecoration = 'underline')
        }
        onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
      >
        {dailyQuestion.title}
      </a>
    </div>
  );
};
export default memo(TodaysQuestionCard);

import { InputTextarea } from 'primereact/inputtextarea';

import React, { useEffect, useState } from 'react';

import { InterviewItem } from '../../Types/InterviewItem';

type Props = {
  interview: InterviewItem;
  interviewData: InterviewItem[];
};

const InterviewNotes = ({ interview, interviewData }: Props) => {
  const updateTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value); // re render component to show changes
    interview.notes = e.target.value; // save updates to interview item
    // save changes to local storage
    localStorage.setItem('interviews', JSON.stringify(interviewData));
  };

  const [note, setNote] = useState<string>();
  const [localName, setLocalName] = useState<string>();
  useEffect(() => {
    setNote(interview.notes);
    setLocalName(interview.company);
  }, [interview.company]);

  return (
    <div>
      {interview.company != '' && (
        <>
          <h2 style={{ textAlign: 'center' }}>Notes for {localName}</h2>
          <InputTextarea
            style={{
              width: '100%',
              minHeight: '10rem',
              borderColor: 'gray',
              maxWidth: '100%',
              resize: 'vertical',
            }}
            value={note}
            onChange={updateTextArea}
          />
        </>
      )}
    </div>
  );
};

export default InterviewNotes;

type Props = {
  errorType: string;
};

const LeetcodeErrorCard = ({ errorType }: Props) => {
  return (
    <div className="stats-container" style={{}}>
      {errorType == 'usernameNotFound' && (
        <h3>Leetcode username not found :(</h3>
      )}
      {errorType == 'networkError' && <h3>Network error, please wait :(</h3>}
    </div>
  );
};

export default LeetcodeErrorCard;

import { useState, useEffect } from 'react';
import QuestionDifficultyCard from './components/QuestionDifficultyCard/QuestionDifficultyCard';
import QuestionTagCard from './components/QuestionTagCard/QuestionTagCard';
import QuestionLanguageCard from './components/QuestionLanguageCard/QuestionLanguageCard';
import TodaysQuestionCard from './components/TodaysQuestionCard/TodaysQuestionCard';
import SettingsScreen from './components/SettingsScreen/SettingsScreen';
import 'primeicons/primeicons.css';
import CurrentInterviews from './components/InterviewComponent/CurrentInterviews';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';

import Leaderboard from './components/Leaderboard/Leaderboard';
import DateTimeCard from './components/HeaderCard/DateTimeCard/DateTimeCard';
import SearchBarCard from './components/HeaderCard/SearchBarCard/SearchBarCard';
import 'primeicons/primeicons.css';
import NoneCard from './components/DefaultCard/NoneCard';
import RecentSubmissionsCard from './components/RecentSubmissionsCard/RecentSubmissionsCard';

import { Dialog } from 'primereact/dialog';
import ExtensionCard from './components/ExtensionCard/ExtensionCard';
import NewUserCard from './components/NewUserCard/NewUserCard';

function App() {
  const [username, setUsername] = useState<string>('');
  const [showSettings, setShowSettings] = useState(false);
  const [startPageMode, setStartPageMode] = useState<boolean>(true);
  const [extensionVisible, setExtensionVisible] = useState<boolean>(false);
  const [topFourComponents, setTopFourComponents] = useState<string[]>([
    'Difficulty',
    'Tags',
    'Language',
    'TodaysQuestion',
  ]);



  const defaultTopFour = JSON.stringify(topFourComponents)


  useEffect(() => {
    const fetchLocalStorage = () => {
      let leetcodeUsername = '';
      let tempMode: string | boolean = 'false';
      let tempTopFour: string[] = [];
      try {
        leetcodeUsername = localStorage.getItem('username') ?? '';
        tempMode = localStorage.getItem('startPageMode')?.toString() ?? 'true';
        tempTopFour = JSON.parse(
          localStorage.getItem('topFour') ?? defaultTopFour,
        );
      } catch {
        // corrupted storage
        localStorage.removeItem('interviews');
        leetcodeUsername = '';

        localStorage.removeItem('startPageMode');
        tempMode = 'false';

        localStorage.removeItem('topFour');
        tempTopFour = JSON.parse(defaultTopFour);
      }
      setUsername(leetcodeUsername);
      setStartPageMode(tempMode === 'true');
      setTopFourComponents(tempTopFour);
    };

    fetchLocalStorage();
  }, []);

  const renderComponent = (componentName: string) => {
    switch (componentName) {
      case 'Difficulty':
        return <QuestionDifficultyCard username={username} />;
      case 'Language':
        return <QuestionLanguageCard username={username} />;
      case 'Tags':
        return <QuestionTagCard username={username} />;
      case 'TodaysQuestion':
        return <TodaysQuestionCard />;
      case 'Leaderboard':
        return <Leaderboard />;
      case 'RecentSubmissions':
        return <RecentSubmissionsCard username={username} />;
      case 'None':
        return <NoneCard />;
      default:
        console.log('ERROR ON SETTINGS COMPONENT INSIDE RENDER COMPONENT');
        return <TodaysQuestionCard />;
    }
  };

  return (
    <div className="App">
      <div className="headerCard">
        <DateTimeCard />
        <SearchBarCard />
      </div>

      {startPageMode && (
        <div style={{ minWidth: '60vw', marginBottom: '3rem' }}>
          <CurrentInterviews />
        </div>
      )}

      {username === '' ? (
        <NewUserCard username={username} setUsername={setUsername} />
      ) : (
        <div className="dashboard-container">
          {renderComponent(topFourComponents[0])}
          {renderComponent(topFourComponents[1])}
          {renderComponent(topFourComponents[2])}
          {renderComponent(topFourComponents[3])}
        </div>
      )}

      <Sidebar
        visible={showSettings}
        position="right"
        onHide={() => setShowSettings(false)}
        className="settingsSideBar"
      >
        <SettingsScreen
          username={username}
          setUsername={setUsername}
          startPageMode={startPageMode}
          setStartPageMode={setStartPageMode}
          topFourComponents={topFourComponents}
          setTopFourComponents={setTopFourComponents}
        />

      </Sidebar >
      <Button icon="pi pi-cog" size="large" rounded text onClick={() => setShowSettings(true)} style={{
        position: 'fixed',
        top: '20px',
        right: '20px',
        zIndex: 999
      }} />

      <a href="/#" style={{
        color: '#FCA311', fontSize: '1.2rem', position: 'fixed',
        top: '20px',
        left: '20px',
        zIndex: 999
      }} onClick={() => setExtensionVisible(prev => !prev)} onKeyDown={() => setExtensionVisible(prev => !prev)}> Get the Extension!</a>

      <Dialog header="Extensions" style={{ width: '500px' }} visible={extensionVisible} onHide={() => setExtensionVisible(false)}>
        <ExtensionCard />
      </Dialog>
    </div >
  );
}

export default App;
